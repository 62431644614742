
#welcome {
    height: 95vh;
    height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5vh;
    position: relative;
    overflow: hidden;

    // For back image blurring
    // Fixed position rather than background-attachment fixed to avoid resize glitch on mobile scroll
    &::after {
        content: "";
        position: fixed;
        left: -0.5rem;
        top: -0.5rem;
        width: calc( 100% + 1rem );
        height: calc( 95vh + 1rem );
        height: calc( 100svh + 1rem );
        z-index: -1;
    }

    #site-logo,
    #site-title,
    #site-description,
    #scroll-down {
        z-index: 99;
        margin: 0;
        position: relative;
        display: block;
    }

    #site-title {
        font-size: 2em;
        text-align: center;

        @media screen and (min-width: $medium) {
            font-size: 2.5em;
        }
    }

    #site-logo {
        width: 50%;
        max-width: 13rem;
        height: auto;

        @media screen and (min-width: $medium) {
            max-height: 35vh;
            width: auto;
        }
    }

    #scroll-down {
        position: absolute;
        bottom: 1rem;
        width: 2rem;
        height: auto;
    }

    @media screen and (min-width: $medium) {
        height: 100vh;
    }
}

#mission {
    text-align: center;
    width: 100%;
    padding: 4rem 1rem;

    h2 {
        font-size: 2.5em;
        line-height: 1;
        text-transform: uppercase;
        font-weight: $heavy-font-weight;

        @media screen and (min-width: $medium) {
            font-size: 4em;
        }

    }
}


.gallery {
    display: flex;
    flex-wrap: wrap;

    .image {
        width: 50%;
        &.wide { width: 100%; }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            vertical-align: middle;
        }

        @media screen and (min-width: $medium) {
            width: 33.33333333%;
            &.wide { width: 66.66666666%; }
        }

        @media screen and (min-width: $large) {
            width: 25%;
            &.wide { width: 50%; }
        }
    }
}

#about {
    text-align: center;
    padding: 3rem max( 1rem, calc( (100% - 55ch) / 2 ) );
    // width: 100%;
    // margin: 0 auto;
    // max-width: 50ch;

    h2 {
        font-size: 2.5em;
        line-height: 1;
        text-transform: uppercase;
        font-weight: $heavy-font-weight;

        @media screen and (min-width: $medium) {
            font-size: 4em;
        }

    }
}

#main-footer {
    padding: 2em 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    font-size: 0.85rem;
    text-align: center;

    div {
        width: 100%;
    }

    p {
        margin: 0;
    }
}
