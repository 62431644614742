
body {
    color: $text-colour;
    background: $back-colour;
}

a {
    color: $text-colour;
    text-decoration-color:$accent-colour;

    &:hover,
    &:active {
        color: $accent-colour;
    }
}

blockquote {
    border-left-color: $accent-colour;
}


#welcome {
    color: $text-invert;
    // background-color: #333;

    &::after {
        background-image: url( '../images/' + $welcome-image );
        background-size: cover;
        background-repeat: none;
        background-position: 75% center;
        // background-attachment: fixed;
    }

    #site-logo,
    #site-title,
    #site-description,
    #scroll-down {
        color: $text-invert;
    }
}

#mission {
    background: $back-dark;
    color: $text-invert;
}

#about {
    background-color: $back-colour;
}

.gallery {
    background-color: $back-dark;
}

#main-footer {
    background-color: $back-colour;
}

