@media (prefers-reduced-motion: no-preference) {

    @keyframes fadein {
        from { opacity: 0.0; }
        to   { opacity: 1.0; }
    }

    @keyframes fadedown {
        // from { opacity: 1.0; }
        // to   { opacity: 0.3; }
        from { filter: blur( 0px ) brightness( 1.0 ); }
        to   { filter: blur( 2px ) brightness( 0.6 ); }
    }

    @keyframes moveup {
        from { top: 3rem; }
        to   { top: 0; }
    }

    @keyframes wiggle {
        0%   { bottom: 1rem; }
        25%  { bottom: 0.8rem; }
        50%  { bottom: 1rem; }
        75%  { bottom: 0.8rem; }
        100% { bottom: 1rem; }
    }

    #welcome {

        &::after {
            filter: blur( 0 ) brightness( 1.0 );
            animation: fadedown 4000ms forwards 1000ms;
        }

        #site-logo,
        #site-title,
        #site-description,
        #scroll-down {
            opacity: 0;
        }

        #site-logo {
            animation: fadein 2000ms forwards 1000ms;
        }

        #site-title {
            animation: fadein 1000ms forwards 2000ms, moveup 1000ms forwards 2000ms;
        }

        #site-description {
            animation: fadein 1000ms forwards 2500ms, moveup 1000ms forwards 2500ms;
        }

        #scroll-down {
            animation: fadein 500ms forwards 3500ms, wiggle 500ms forwards 4000ms;
        }
    }


    .gallery {
        .image {
            img {
                transition-property: filter;
                transition-duration: 1000ms;
                filter: grayscale( 1.0 ) brightness( 0.5 );
            }

            &:nth-child( 2n ) {
                img{
                    transition-duration: 500ms;
                }
            }

            &:nth-child( 3n ) {
                img{
                    transition-duration: 2000ms;
                }
            }

            &.visible {
                img {
                    filter: grayscale( 0.0 ) brightness( 1.0 );
                }
            }
        }
    }

}


