#banner {
  position: fixed;
  z-index: 9999;
  top: 15vh;
  left: 50vw;
  transform: translate(-50%, 0);
  width: 80%;
  max-width: 40rem;
  background-color: #fff;
  color: #222;
  padding: 2rem;
  text-align: center;
  border: 3px solid #000;
  box-shadow: 0 0.5rem 1rem #0008;
}
