html {
    font-family: $font-family;
    font-weight: $normal-font-weight;
    font-size: $base-font-size;
    line-height: 1.3;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: $light-font-weight;
    text-rendering: optimizeLegibility; /* enable common ligatures and kerning */
}

a {
    -webkit-transition: $global-transition;
    transition: $global-transition;
}
